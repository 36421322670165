import React from "react";
import "../styling/Loading.css";

export default class Spinner extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div id="spinner-container" className="spinner-container">
        <div className="Spinner"></div>
      </div>
    );
  }
}
