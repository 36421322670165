import React, { Component } from "react";
import LocationButton from "./LocationButton";

class AdWithUs extends Component {
  constructor() {
    super();
    this.state = {
      stores: [],
      count: 0,
      suggestions: [],
      cities: [],
    };
  }

  // Fetch the information from the database
  componentDidMount() {
    var storeUrl = process.env.REACT_APP_STORE_DROPDOWN;

    fetch(storeUrl)
      .then((res) => res.json())
      .then((stores) => this.setState({ stores }));
  }

  generateDropdown() {
    //if(this.state.count === 16){
    // Get all of the coordinates and push them to an array.
    const storeCoords = [];

    for (var x = 0; x < this.state.stores.length; x++) {
      const tempArray = [
        this.state.stores[x].StoreCity +
          "," +
          this.state.stores[x].StoreState +
          "," +
          this.state.stores[x].StoreID,
      ];
      storeCoords.push(tempArray);
    }

    storeCoords.sort();
    storeCoords.reverse();

    this.setState({ cities: storeCoords });
  }

  onTextChanged = (e) => {
    this.generateDropdown();
    const value = e.target.value;
    let suggestions = [];

    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      suggestions = this.state.cities.sort().filter((v) => regex.test(v));
      this.setState(() => ({ suggestions }));
      document.getElementsByClassName("closeBtn1")[0].style.display = "block";
    }
    if (document.getElementsByClassName("Dropdown-Content")[0] !== undefined) {
      document.getElementsByClassName("Dropdown-Content")[0].style.display =
        "block";
    }
  };

  renderSuggestions() {
    const { suggestions } = this.state;
    var cityArray = suggestions.map((cities) => cities);
    var splitArray = [];
    var formattedRows = [];

    if (suggestions.length === 0) {
      return null;
    }

    for (var z = 0; z < cityArray.length; z++) {
      splitArray.push(cityArray[z][0].split(","));
    }

    for (var y = 0; y < splitArray.length; y++) {
      formattedRows.push(
        <tr key={y}>
          <a
            href={
              "../location/?city=" +
              splitArray[y][0] +
              "&state=" +
              splitArray[y][1]
            }
          >
            {splitArray[y][0] + ", " + splitArray[y][1]}
          </a>
        </tr>
      );
    }

    return <div className="Dropdown-Content">{formattedRows}</div>;
  }

  clear() {
    var input = document.getElementsByClassName("CityInput1")[0];
    var div = document.getElementsByClassName("Dropdown-Content")[0];

    if (div !== undefined) {
      input.value = "";
      div.style.display = "none";
      document.getElementsByClassName("closeBtn1")[0].style.display = "none";
    }
  }

  renderTextBox() {
    if (document.getElementsByClassName("A2")[0] !== "") {
      document.getElementsByClassName("A2")[0].innerHTML = "";
      document.getElementsByClassName("A2")[0].onClick = null;
      document.getElementsByClassName("A2")[0].style.width = "0%";
      document.getElementsByClassName("A2Input")[0].style.width = "14%";
      document.getElementsByClassName("A2Input")[0].style.display = "block";
    }
  }

  render() {
    document.title = "Shop Your Town | Advertise";

    return (
      <div className="MasterWrapper">
        <div className="MasterHeader">
          <div className="Spacer2"></div>
          <div className="A1">
            <LocationButton />
          </div>
          <div className="Spacer4"></div>
          <div
            className="A2"
            style={{ cursor: "pointer" }}
            onClick={this.renderTextBox}
          >
            Find Cities
          </div>
          <div className="A2Input">
            <input
              className="CityInput"
              placeholder="City Search..."
              type="text"
              onChange={this.onTextChanged}
              autocomplete="off"
            ></input>
            <span className="closeBtn1" onClick={this.clear}>
              &times;
            </span>
            {this.renderSuggestions()}
          </div>
          <div className="Spacer6"></div>
          <div className="LogoContainer">
            <a href="../">
              <img
                className="SYTLogoMain"
                src="/image/SYTlogoFC.png"
                alt="SYT-Logo"
              ></img>
            </a>
          </div>
          <div className="Spacer4"></div>
          <div className="A3">
            <a
              href="../AboutUs"
              style={{ textDecoration: "none", color: "black" }}
            >
              About Us
            </a>
          </div>
          <div className="Spacer4"></div>
          <div className="A4">
            <a
              href="mailto:Sales@shop-yourtown.com?subject=Website Inquiry"
              style={{ textDecoration: "none", color: "black" }}
            >
              Contact Us
            </a>
          </div>
          <div className="Spacer4"></div>
          <div className="A5">
            <a
              href="../Advertising"
              style={{ textDecoration: "none", color: "black" }}
            >
              Advertise
            </a>
          </div>
        </div>

        <script>
          {
            (window.onscroll = function () {
              var header = document.getElementsByClassName("MasterHeader")[0];
              //var sticky = header.offsetTop;

              if (
                document.body.scrollTop > 1 ||
                document.documentElement.scrollTop > 1
              ) {
                header.classList.add("StickySmall");
              } else {
                header.classList.remove("StickySmall");
              }
            })
          }
        </script>

        {window.scrollTo(0, 0)}
        <div className="AdvertiseContainer">
          <p>
            <b
              style={{ textAlign: "left", color: "#0037FF", fontSize: "24px" }}
            >
              How Does This Work:
            </b>
          </p>
          <p style={{ textAlign: "left", fontSize: "18px", lineHeight: "1.5" }}>
            We purchases ad space in bulk, for YourTown on over 100,000 local
            and nationally recognized websites. Many websites are premier sites
            like accuweather.com and washingtonpost.com, other examples are your
            local news organizations or businesses that have a website that have
            been screened and agreed to sell all unsold ad space at a deep
            discount. We distribute the cost and exposures equally among all the
            advertisers, on YourTown page, at www.shop-yourtown.com. Each
            advertiser’s ad is displayed in rotation equally and every ad click
            comes back to YourTown page, at www.shop-yourtown.com increasing
            each advertisers exposure on every click.
          </p>
          <p>
            <b
              style={{ textAlign: "left", color: "#0037FF", fontSize: "24px" }}
            >
              How Much Does It Cost:
            </b>
          </p>
          <p style={{ textAlign: "left", fontSize: "18px", lineHeight: "1.5" }}>
            We offer banner ads on these websites for less than $200 a month and
            pre-roll video ads for less the $300 per month.
          </p>
          <p>
            <b
              style={{ textAlign: "left", color: "#0037FF", fontSize: "24px" }}
            >
              Who Do We Target:
            </b>
          </p>
          <p style={{ textAlign: "left", fontSize: "18px", lineHeight: "1.5" }}>
            We target people physically in YourTown, on the websites that they
            are looking at. We re-target the same people that clicked on any of
            our advertisers’ ads up to seven (7) times. The ad presented to a
            re-targeted person is not always a repeat of the same ad. All ads
            are presented in rotation, meaning the next advertiser in the
            rotation will be presented to the next target or re-target. A
            benefit of rotational ads is that the re-target is continuing to be
            targeted with ads it saw from the original click on YourTown page,
            at www.shop-yourtown.com. One click provides a Target with all the
            offers from YourTown.
          </p>
          <p>
            <b
              style={{ textAlign: "left", color: "#0037FF", fontSize: "24px" }}
            >
              How Does YourTown Targeting Work:
            </b>
          </p>
          <p style={{ textAlign: "left", fontSize: "18px", lineHeight: "1.5" }}>
            Every smart phone, tablet or computer has an IP address assigned to
            it by the devices Internet Service Provider (aka an ISP), this
            address tells the internet and web sites where the device (and
            person) is physically located within close proximity. Each website
            is scanned for sold and unsold ad space to determine which ad is
            presented. Organically a YourTown ad will beat national ads because
            we pay slightly more for YourTown.
          </p>
          <p>
            <b
              style={{ textAlign: "left", color: "#0037FF", fontSize: "24px" }}
            >
              How Does A Click Work:
            </b>
          </p>
          <p style={{ textAlign: "left", fontSize: "18px", lineHeight: "1.5" }}>
            Anytime a Target clicks on any YourTown advertisers ad, the Target
            is re-directed to YourTown page at www.shop-yourtown.com. The Target
            is then exposed to all YourTown advertisers, making every click
            count for every advertiser on YourTown page.
          </p>
          <p>
            <b
              style={{ textAlign: "left", color: "#0037FF", fontSize: "24px" }}
            >
              How Does The Target Get My Offer:
            </b>
          </p>
          <p style={{ textAlign: "left", fontSize: "18px", lineHeight: "1.5" }}>
            When a Target goes to YourTown page and clicks on your ad it brings
            them to a popup page just for your business, this page provides all
            the information Google has about your business, address, hours and
            contact info. We provide links to your website, social media and
            this is where Targets can find your offer to print it, download it,
            save it on their phones home screen or desktop for easy access when
            redeeming at your business.
          </p>
        </div>

        <footer className="Footer">
          <div style={{ fontSize: "16px", color: "#4d4d4d" }}>
            <LocationButton />
          </div>
          <div>
            <a
              href="../AboutUs"
              style={{
                fontSize: "16px",
                textDecoration: "none",
                color: "#4d4d4d",
              }}
            >
              About Us
            </a>
          </div>
          <div>
            <a
              href="mailto:Sales@shop-yourtown.com"
              style={{
                fontSize: "16px",
                textDecoration: "none",
                color: "#4d4d4d",
              }}
            >
              Contact Us
            </a>
          </div>
          <div>
            <a
              href="../Advertising"
              style={{
                fontSize: "16px",
                textDecoration: "none",
                color: "#4d4d4d",
              }}
            >
              Advertise
            </a>
          </div>
          <a
            href="https://www.facebook.com/pages/category/Discount-Store/Shopyourtown-2230097663912538/"
            className="fa fa-facebook"
            target="_blank"
            rel="noopener noreferrer"
          >
            {}
          </a>
          <br />
          <br />
          <div>
            <p
              style={{
                fontSize: "16px",
                textDecoration: "none",
                color: "#4d4d4d",
              }}
            >
              © 2019 SYT Solutions LLC
            </p>
          </div>
        </footer>
      </div>
    );
  }
}

export default AdWithUs;
