import React, { Component } from "react";
import LocationButton from "./LocationButton";
import $ from "jquery";
import * as Sentry from "@sentry/browser";
import Card from "../components/Card";
import Loading from "../components/Loading";

const RSSKey = process.env.REACT_APP_RSS;

const VERTICAL = {
  height: "100%",
  width: "97.25%",
  type: "vertical",
  top: null,
  size: "LeftAdContainer",
};
const DOUBLE = { height: "100%", top: null, size: "LeftAdContainer" };
const SINGLE = { height: "44%", top: "15%", size: "LeftAdContainer" };
const BANNER = {
  height: "100%",
  width: "94%",
  top: null,
  size: "BannerAdContainer",
};
const BANNER_VERT = {
  height: "100%",
  top: null,
  size: "VerticalBannerAdContainer",
};

const BASE_URL = `https://s3-us-west-2.amazonaws.com/ata.images/`;

class NewLaunchAll extends Component {
  constructor() {
    super();
    this.state = {
      citypage: [],
      stores: [],
      allAds: [],
      RenderCount: 0,
      count: 1,
      suggestions: [],
      cities: [],
      ads: [],
      error: null,
      eventId: null,
      loading: true,
    };
  }

  // Fetch the information from the database
  componentDidMount() {
    var url = process.env.REACT_APP_LOCATION + window.location.search;
    var storeUrl = process.env.REACT_APP_STORE_DROPDOWN;

    fetch(url)
      .then((res) => res.json())
      .then(async (citypage) => {
        const ads = await this.getAds(citypage);
        this.setState({ citypage, ads, loading: false }, () => {
          this.getCityImage();
          this.getChamberImage();
          this.getLotoImage();
        });
      });
    fetch(storeUrl)
      .then((res) => res.json())
      .then((stores) => this.setState({ stores }));
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  generateDropdown() {
    //if(this.state.count === 16){
    // Get all of the coordinates and push them to an array.
    const storeCoords = [];

    for (var x = 0; x < this.state.stores.length; x++) {
      const tempArray = [
        this.state.stores[x].StoreCity +
          "," +
          this.state.stores[x].StoreState +
          "," +
          this.state.stores[x].StoreID,
      ];
      storeCoords.push(tempArray);
    }

    storeCoords.sort();
    storeCoords.reverse();

    this.setState({ cities: storeCoords });
  }

  mapcitypage(i) {
    // Each component of the business is mapped to a variable
    const bname = this.state.citypage.map((citypage) => citypage.BusinessName);
    const bphone = this.state.citypage.map(
      (citypage) => citypage.BusinessPhoneNumber
    );
    const bmap = this.state.citypage.map((citypage) => citypage.BusinessMapURL);
    const monday = this.state.citypage.map((citypage) => citypage.MondayHours);
    const tuesday = this.state.citypage.map(
      (citypage) => citypage.TuesdayHours
    );
    const wednesday = this.state.citypage.map(
      (citypage) => citypage.WednesdayHours
    );
    const thursday = this.state.citypage.map(
      (citypage) => citypage.ThursdayHours
    );
    const friday = this.state.citypage.map((citypage) => citypage.FridayHours);
    const saturday = this.state.citypage.map(
      (citypage) => citypage.SaturdayHours
    );
    const sunday = this.state.citypage.map((citypage) => citypage.SundayHours);
    const facebook = this.state.citypage.map(
      (citypage) => citypage.BusinessFacebook
    );
    const twitter = this.state.citypage.map(
      (citypage) => citypage.BusinessTwitter
    );
    const instagram = this.state.citypage.map(
      (citypage) => citypage.BusinessInstagram
    );
    const website = this.state.citypage.map(
      (citypage) => citypage.BusinessWebsite
    );
    const address = this.state.citypage.map(
      (citypage) => citypage.BusinessAddress
    );

    return (
      // Render the modal window and all of it's information
      <div className="Modal-Content">
        <span className="closeBtn" onClick={() => $("#Ad-Modal").toggle()}>
          &times;
        </span>
        <header className="Modal-Header">
          <h1>{bname[i]}</h1>
        </header>
        <div className="Modal-Body">
          <iframe
            title="Google Map"
            className="GoogleMap"
            src={bmap[i]}
            width="450px"
            height="470px"
          ></iframe>
          <div className="BusinessInfo">
            <p>
              <b>Phone Number: </b>
            </p>
            <p>{bphone[i]}</p>
            <p>
              <b>Address</b>
            </p>
            <p>{address[i]}</p>
            <p>
              <b>Hours:</b>
            </p>
            <ul className="ul">
              <li>Monday: {monday[i]}</li>
              <li>Tuesday: {tuesday[i]}</li>
              <li>Wednesday: {wednesday[i]}</li>
              <li>Thursday: {thursday[i]}</li>
              <li>Friday: {friday[i]}</li>
              <li>Saturday: {saturday[i]}</li>
              <li>Sunday: {sunday[i]}</li>
            </ul>
            <p>
              <b>Current Offer:</b>
            </p>
            <a
              className="offerButton"
              id="OB"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "white" }}
              href={this.state.ads.length ? this.state.ads[i - 1].src : "#"}
            >
              Save offer
            </a>

            <p>
              <a
                href={facebook[i]}
                className="fa fa-facebook"
                target="_blank"
                rel="noopener noreferrer"
              >
                {}
              </a>
              <a
                href={twitter[i]}
                className="fa fa-twitter"
                target="_blank"
                rel="noopener noreferrer"
              >
                {}
              </a>
              <a
                href={instagram[i]}
                className="fa fa-instagram"
                target="_blank"
                rel="noopener noreferrer"
              >
                {}
              </a>
              <a
                href={website[i]}
                className="fa fa-google"
                target="_blank"
                rel="noopener noreferrer"
              >
                {}
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }

  scrollToLinks() {
    $(window).scrollTop($("#sport").offset().top - 155);
  }

  ImagesAndLinks(link) {
    return !this.state.loading ? (
      <div className="ImageTiles">
        <div className="CityImage">
          <a
            href={
              this.state.citypage.map((citypage) => citypage.LocalCityPage)[0]
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              id="CityImg"
              height="105px"
              width="235px"
              alt="City Logo"
              style={{
                backgroundColor: this.state.citypage.map(
                  (citypage) => citypage.SecondaryColor
                )[0],
              }}
            ></img>
          </a>
          <p style={{ textAlign: "center", marginTop: "50px" }}>
            {this.state.citypage.map((citypage) => citypage.City)[0] +
              "'s City Page"}
          </p>
        </div>
        <div className="ChamberImage">
          <a
            href={
              this.state.citypage.map(
                (citypage) => citypage.LocalChamberOfCommerce
              )[0]
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              id="ChamberImg"
              height="105px"
              width="235px"
              alt="Chamber Logo"
              style={{
                backgroundColor: this.state.citypage.map(
                  (citypage) => citypage.ChamberImageColor
                )[0],
              }}
            ></img>
          </a>
          <p style={{ textAlign: "center", marginTop: "50px" }}>
            {this.state.citypage.map((citypage) => citypage.City)[0] +
              "'s Chamber of Commerce Page"}
          </p>
        </div>
        <div className="HideScroll2"></div>
        <div className="MovieFeed" style={{ height: "767px" }}></div>
        <div className="LotoImage">
          <a
            href={
              this.state.citypage.map((citypage) => citypage.StateLottery)[0]
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              id="LotoImg"
              height="105px"
              width="235px"
              alt="Loto Logo"
              style={{
                backgroundColor: this.state.citypage.map(
                  (citypage) => citypage.TrinaryColor
                )[0],
              }}
            ></img>
          </a>
          <p style={{ textAlign: "center", marginTop: "50px" }}>
            Lottery Results
          </p>
        </div>
        <div className="IndeedImage">
          <a
            href={
              this.state.citypage.map(
                (citypage) => citypage.LocalJobPostings
              )[0]
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/image/Indeed-Logo.jpg"
              alt="Indeed Logo"
              height="105px"
              width="235px"
            ></img>
          </a>
          <p style={{ textAlign: "center", marginTop: "50px" }}>
            {this.state.citypage.map((citypage) => citypage.City)[0] +
              " Job Postings"}
          </p>
        </div>
        <div className="RealtorImage">
          <a
            href={
              this.state.citypage.map((citypage) => citypage.LocalRealEstate)[0]
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/image/Realtor.com-Logo.jpg"
              alt="Realtor.com Logo"
              height="105px"
              width="235px"
            ></img>
          </a>
          <p style={{ textAlign: "center", marginTop: "50px" }}>
            {this.state.citypage.map((citypage) => citypage.City)[0] +
              " Real Estate Listings"}
          </p>
        </div>
        <div className="EventbriteImage">
          <a
            href={
              this.state.citypage.map((citypage) => citypage.LocalEvents)[0]
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/image/Eventbrite-Logo.png"
              alt="Eventbrite Logo"
              height="105px"
              width="235px"
            ></img>
          </a>
          <p style={{ textAlign: "center", marginTop: "50px" }}>
            {"Events happening in " +
              this.state.citypage.map((citypage) => citypage.City)[0]}
          </p>
        </div>
      </div>
    ) : null;
  }

  loadMovies(link) {
    $.get(
      "https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Fwww.fandango.com%2Frss%2Fmoviesnearme_" +
        link +
        ".rss&api_key=" +
        RSSKey,
      function (data) {
        var len = data.items.length;
        var div = document.getElementsByClassName("MovieFeed")[0];
        var banner = document.createElement("p");
        banner.className = "MovieP";
        banner.innerHTML = "Movies Now Playing";
        div.appendChild(banner);

        for (var k = 0; k < len; k++) {
          //<p className="MovieP">Movies Now Playing</p>
          var title = data.items[k].title;
          var content = data.items[k].content;
          var a = document.createElement("a");
          var titleNode = document.createTextNode(title);

          a.style.fontWeight = "bold";
          a.style.textDecoration = "none";
          a.style.color = "black";
          a.setAttribute("href", data.items[k].link);
          a.setAttribute("target", "_blank");
          a.setAttribute("rel", "noopener noreferrer");
          a.appendChild(titleNode);

          div.appendChild(a);
          div.innerHTML += content;
        }
      }
    );
  }

  renderAds() {
    if (this.state.citypage.length - 1 === 0) {
      return <div id="AdHolder"></div>;
    } else {
      return (
        <div
          id="AdHolder"
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "flex-start",
          }}
        >
          {this.state.ads.map((ad, index) => (
            <Card
              id={index}
              img_src={ad.src}
              dims={ad.size}
              name={ad.name}
              address={ad.address}
              phone={ad.phone}
              onClick={this.getAdInfo}
            />
          ))}
        </div>
      );
    }
  }

  getAdInfo = (ad_index = 0) => {
    this.setState({ count: ad_index }, () => {
      $("#Ad-Modal").toggle();
    });
  };

  getAds = (citypage) => {
    return new Promise(async (resolve, reject) => {
      // Get all the customer numbers for the city and push them to an array
      const ads = citypage.slice(1).map((ad) => ({
        customer_id: ad.CustomerNumber,
        name: ad.BusinessName,
        address: ad.BusinessAddress,
        phone: ad.BusinessPhoneNumber,
        store_id: ad.StoreNumber,
        prefix: ad.AdPrefix,
        last_ran: ad.LastRan,
        size:
          ad.IsBanner === "Yes"
            ? BANNER
            : ad.IsDouble === "Yes"
            ? DOUBLE
            : ad.IsSingle === "Yes"
            ? SINGLE
            : ad.IsVertical === "Yes"
            ? VERTICAL
            : BANNER_VERT,
        src: `${BASE_URL}Ads/${ad.CustomerNumber}_${ad.StoreNumber}_${ad.LastRan}_${ad.AdPrefix}.jpg`,
      }));

      resolve(ads);
    });
  };

  async getCityImage() {
    const city = this.state.citypage[0];
    let _img = document.getElementById("CityImg");
    let newImg = new Image();
    newImg.src = `${BASE_URL}City_Images/${city.City}-${city.State}.jpg`;
    newImg.onload = () => {
      // try loading jpg first
      _img.src = `${BASE_URL}City_Images/${city.City}-${city.State}.jpg`;
    };

    newImg.onerror = () => {
      _img.src = `${BASE_URL}City_Images/${city.City}-${city.State}.png`;
    };
  }

  async getChamberImage() {
    const city = this.state.citypage[0];
    let _img = document.getElementById("ChamberImg");
    let newImg = new Image();
    newImg.src = `${BASE_URL}Chamber_Images/${city.City}-${city.State}.jpg`;
    newImg.onload = () => {
      // try loading jpg first
      _img.src = `${BASE_URL}Chamber_Images/${city.City}-${city.State}.jpg`;
    };

    newImg.onerror = () => {
      _img.src = `${BASE_URL}Chamber_Images/${city.City}-${city.State}.png`;
    };
  }

  async getLotoImage() {
    const city = this.state.citypage[0];
    let _img = document.getElementById("LotoImg");
    let newImg = new Image();
    newImg.src = `${BASE_URL}Loto_Images/${city.State}.jpg`;
    newImg.onload = () => {
      // try loading jpg first
      _img.src = `${BASE_URL}Loto_Images/${city.State}.jpg`;
    };

    newImg.onerror = () => {
      _img.src = `${BASE_URL}Loto_Images/${city.State}.png`;
    };
  }

  onTextChanged = (e) => {
    this.generateDropdown();
    const value = e.target.value;
    let suggestions = [];

    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      suggestions = this.state.cities.sort().filter((v) => regex.test(v));
      this.setState(() => ({ suggestions }));
      document.getElementsByClassName("closeBtn1")[0].style.display = "block";
    }
    if (document.getElementsByClassName("Dropdown-Content")[0] !== undefined) {
      document.getElementsByClassName("Dropdown-Content")[0].style.display =
        "block";
    }
  };

  renderSuggestions() {
    const { suggestions } = this.state;
    var cityArray = suggestions.map((cities) => cities);
    var splitArray = [];
    var formattedRows = [];

    if (suggestions.length === 0) {
      return null;
    }

    for (var z = 0; z < cityArray.length; z++) {
      splitArray.push(cityArray[z][0].split(","));
    }

    for (var y = 0; y < splitArray.length; y++) {
      formattedRows.push(
        <tr key={y}>
          <a
            href={
              "../location/?city=" +
              splitArray[y][0] +
              "&state=" +
              splitArray[y][1]
            }
          >
            {splitArray[y][0] + ", " + splitArray[y][1]}
          </a>
        </tr>
      );
    }

    return <div className="Dropdown-Content">{formattedRows}</div>;
  }

  clear() {
    var input = document.getElementsByClassName("CityInput")[0];
    var div = document.getElementsByClassName("Dropdown-Content")[0];

    if (div !== undefined) {
      input.value = "";
      div.style.display = "none";
      document.getElementsByClassName("closeBtn1")[0].style.display = "none";
    }
  }

  renderTextBox() {
    if (document.getElementsByClassName("A2")[0] !== "") {
      document.getElementsByClassName("A2")[0].innerHTML = "";
      document.getElementsByClassName("A2")[0].onClick = null;
      document.getElementsByClassName("A2")[0].style.width = "0%";
      document.getElementsByClassName("A2Input")[0].style.width = "14%";
      document.getElementsByClassName("A2Input")[0].style.display = "block";
    }
  }

  comingSoonModal() {
    if (this.state.citypage.length === 0 && !this.state.loading) {
      var url = window.location.href;
      var split1 = url.split("?")[1];
      var split2 = split1.split("&");
      var city = split2[0].split("=")[1];
      var state = split2[1].split("=")[1];
      var div = document.getElementsByClassName("CityHolder")[0];

      if (div !== null && div !== undefined) {
        this.renderCities().then(function (results) {
          if (div.innerHTML === "") {
            try {
              var a0 = document.createElement("a");
              a0.href =
                "/location/?city=" + results[0][1] + "&state=" + results[0][2];
              a0.innerHTML = results[0][1] + ", " + results[0][2];
              a0.style.textDecoration = "none";
              a0.style.color = "blue";

              var a1 = document.createElement("a");
              a1.href =
                "/location/?city=" + results[1][1] + "&state=" + results[1][2];
              a1.innerHTML = results[1][1] + ", " + results[1][2];
              a1.style.textDecoration = "none";
              a1.style.color = "blue";

              var a2 = document.createElement("a");
              a2.href =
                "/location/?city=" + results[2][1] + "&state=" + results[2][2];
              a2.innerHTML = results[2][1] + ", " + results[2][2];
              a2.style.textDecoration = "none";
              a2.style.color = "blue";

              var a3 = document.createElement("a");
              a3.href =
                "/location/?city=" + results[3][1] + "&state=" + results[3][2];
              a3.innerHTML = results[3][1] + ", " + results[3][2];
              a3.style.textDecoration = "none";
              a3.style.color = "blue";

              var a4 = document.createElement("a");
              a4.href =
                "/location/?city=" + results[4][1] + "&state=" + results[4][2];
              a4.innerHTML = results[4][1] + ", " + results[4][2];
              a4.style.textDecoration = "none";
              a4.style.color = "blue";

              var a5 = document.createElement("a");
              a5.href =
                "/location/?city=" + results[5][1] + "&state=" + results[5][2];
              a5.innerHTML = results[5][1] + ", " + results[5][2];
              a5.style.textDecoration = "none";
              a5.style.color = "blue";

              var br = document.createElement("br");
              var br0 = document.createElement("br");
              var br1 = document.createElement("br");
              var br10 = document.createElement("br");
              var br2 = document.createElement("br");
              var br20 = document.createElement("br");
              var br3 = document.createElement("br");
              var br30 = document.createElement("br");
              var br4 = document.createElement("br");
              var br40 = document.createElement("br");

              div.appendChild(a0);
              div.appendChild(br);
              div.appendChild(br0);
              div.appendChild(a1);
              div.appendChild(br1);
              div.appendChild(br10);
              div.appendChild(a2);
              div.appendChild(br2);
              div.appendChild(br20);
              div.appendChild(a3);
              div.appendChild(br3);
              div.appendChild(br30);
              div.appendChild(a4);
              div.appendChild(br4);
              div.appendChild(br40);
              div.appendChild(a5);
              document.getElementsByClassName(
                "ComingSoonModal"
              )[0].style.display = "block";
            } catch (e) {}
          }
        });
      }
      return (
        <div className="ComingSoonModal">
          <div className="ComingSoonModalBody">
            <div className="ComingSoonModalContent">
              <div className="ModalLogoHolder">
                <div className="spacer"></div>
                <img
                  className="SYTLogoMain"
                  src="/image/SYTlogoFC.png"
                  alt="SYT-Logo"
                ></img>
              </div>
              <p className="NoResults">
                It looks like we don't haven any results for {decodeURI(city)},{" "}
                {state} yet.
              </p>
              <br />
              <p className="NoResults">
                In the meantime, check out some nearby towns!
              </p>
              <div className="CityHolder"></div>
            </div>
          </div>
        </div>
      );
    }
  }

  async renderCities() {
    try {
      // Get all of the coordinates and push them to an array.
      const storeCoords = [];
      const alteredCoords2 = [];
      for (var x = 0; x < this.state.stores.length; x++) {
        const tempArray = [
          this.state.stores[x].StoreLatitude,
          this.state.stores[x].StoreLongitude,
          this.state.stores[x].StoreCity,
          this.state.stores[x].StoreState,
        ];
        storeCoords.push(tempArray);
      }

      await $.ajax({
        type: "GET",
        dataType: "json",
        //url: 'https://www.geoip-db.com/json/',
        url:
          "https://api.ipgeolocation.io/ipgeo?apiKey=" +
          process.env.REACT_APP_GEO,

        success: function (responseData) {
          for (var y = 0; y < storeCoords.length; y++) {
            const tempArray2 = [
              Math.abs(storeCoords[y][0] - responseData.latitude) +
                Math.abs(storeCoords[y][1] - responseData.longitude),
              storeCoords[y][2],
              storeCoords[y][3],
              storeCoords[y][4],
            ];
            alteredCoords2.push(tempArray2);
          }
          alteredCoords2.sort();

          //const city = alteredCoords2[0][1];
          //const state = alteredCoords2[0][2];

          //window.history.replaceState([city, state], 'City Info', '/location/?city='+city+'&state='+state);
          //window.location.reload();
        },
      });

      return alteredCoords2;
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    document.title =
      "Shop Your Town | " +
      this.state.citypage.map((citypage) => citypage.City)[0];
    return (
      <div className="MasterWrapper" id="MW">
        <div className="MasterHeader" id="MH">
          <div className="A1">
            <LocationButton />
          </div>
          <div className="Spacer1"></div>
          <div
            className="A2"
            style={{ cursor: "pointer" }}
            onClick={this.renderTextBox}
          >
            Find Cities
          </div>
          <div className="A2Input">
            <input
              className="CityInput"
              placeholder="City Search..."
              type="text"
              onChange={this.onTextChanged}
              autocomplete="off"
            ></input>
            <span className="closeBtn1" onClick={this.clear}>
              &times;
            </span>
            {this.renderSuggestions()}
          </div>
          <div className="Spacer1"></div>
          <div className="A6" onClick={this.scrollToLinks}>
            Local Links
          </div>
          <div className="Spacer1"></div>
          <div className="LogoContainer">
            <a href="../">
              <img
                className="SYTLogoMain"
                src="/image/SYTlogoFC.png"
                alt="SYT-Logo"
              ></img>
            </a>
          </div>
          <div className="Spacer4"></div>
          <div className="A3">
            <a
              href="../AboutUs"
              style={{ textDecoration: "none", color: "black" }}
            >
              About Us
            </a>
          </div>
          <div className="Spacer4"></div>
          <div className="A4">
            <a
              href="mailto:Sales@shop-yourtown.com?subject=Website Inquiry"
              style={{ textDecoration: "none", color: "black" }}
            >
              Contact Us
            </a>
          </div>
          <div className="Spacer4"></div>
          <div className="A5">
            <a
              href="../Advertising"
              style={{ textDecoration: "none", color: "black" }}
            >
              Advertise
            </a>
          </div>
        </div>
        <div className="SecondaryHeader" id="SH">
          <div className="Spacer2"></div>
          <div className="CityPageA">
            <a
              href={
                this.state.citypage.map((citypage) => citypage.LocalCityPage)[0]
              }
              style={{ textDecoration: "none", color: "black" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              City Page
            </a>
          </div>
          <div className="Spacer875"></div>
          <div className="ChamberA">
            <a
              href={
                this.state.citypage.map(
                  (citypage) => citypage.LocalChamberOfCommerce
                )[0]
              }
              style={{ textDecoration: "none", color: "black" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Chamber of Commerce
            </a>
          </div>
          <div className="Spacer4"></div>
          <div className="CityName">
            <span id="CITY">
              {this.state.citypage.map((citypage) => citypage.City)},{" "}
            </span>
            <span id="STATE">
              {this.state.citypage.map((citypage) => citypage.State)}
            </span>
          </div>
          <div className="Spacer875"></div>
          <div className="JobListingA">
            <a
              href={
                this.state.citypage.map(
                  (citypage) => citypage.LocalJobPostings
                )[0]
              }
              style={{ textDecoration: "none", color: "black" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Job Postings
            </a>
          </div>
          <div className="Spacer875"></div>
          <div className="RealEstateA">
            <a
              href={
                this.state.citypage.map(
                  (citypage) => citypage.LocalRealEstate
                )[0]
              }
              style={{ textDecoration: "none", color: "black" }}
              target="_blank"
              rel="noopener noreferrer"
            >
              Real Estate
            </a>
          </div>
        </div>
        <script>
          {
            (window.onscroll = function () {
              var header = document.getElementsByClassName("MasterHeader")[0];
              //var sticky = header.offsetTop;

              if (
                document.body.scrollTop > 1 ||
                document.documentElement.scrollTop > 1
              ) {
                header.classList.add("Sticky");
              } else {
                header.classList.remove("Sticky");
              }
            })
          }
        </script>
        <div className="ContentContainer">
          {window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}
          <div className="WeatherWidget">
            {!this.state.loading ? (
              <a
                className="weatherwidget-io"
                href={
                  this.state.citypage.map(
                    (citypage) => citypage.LocalWeatherURL
                  )[0]
                }
                data-label_1={
                  this.state.citypage.map((citypage) => citypage.City)[0]
                }
                data-label_2="WEATHER"
                data-days="7"
                data-theme="clear"
              >
                {" "}
              </a>
            ) : null}
          </div>

          {!this.state.loading ? this.renderAds() : <Loading />}

          <div className="Sports" id="sport">
            <div
              class="scorestream-widget-container"
              data-ss_widget_type="horzScoreboard"
              data-user-widget-id={
                this.state.citypage.map(
                  (citypage) => citypage.LocalSportsURL
                )[0]
              }
              style={{
                height: "80px",
                width: "99.8%",
                border: "2px solid",
                borderColor: "#FF9704",
                borderRadius: "4px",
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                marginBottom: "50px",
              }}
            ></div>
          </div>

          <div className="NewsFeed">
            <p className="NewsP">Local News</p>
            <iframe
              title="RSS Feed"
              width="100%"
              height="700"
              frameBorder="0"
              seamless="seamless"
              src={
                this.state.citypage.map((citypage) => citypage.LocalNewsRSS)[0]
              }
            ></iframe>
          </div>
          <div className="HideScroll"></div>
          {this.ImagesAndLinks(
            this.state.citypage.map((citypage) => citypage.MovieZip)[0]
          )}
        </div>
        {/* {this.comingSoonModal()} */}

        {!this.state.loading ? (
          <div className="Ad-Modal" id="Ad-Modal">
            {this.mapcitypage(this.state.count)}
          </div>
        ) : null}

        <button
          id="LoadButton"
          style={{ display: "none" }}
          onLoad={this.button16}
          onClick={this.button16}
        ></button>
        <script>
          {setTimeout(function () {
            $("#LoadButton").trigger("click");
            $("#LoadButton").remove();
          }, 1500)}
        </script>
        {/*Scorestream Script*/}
        <script>
          {setTimeout(function () {
            function loadScript(id, src) {
              if (!document.getElementById(id)) {
                var script = document.createElement("script");
                script.id = id;
                script.src = src;
                script.setAttribute("data-domain", "https://scorestream.com");
                script.setAttribute(
                  "data-cdnhost",
                  "https://cdn-scorestream-com.s3.amazonaws.com"
                );
                script.setAttribute("data-uiver", "3");
                script.setAttribute(
                  "data-uiCdn",
                  "https://d2sgc57sw7l730.cloudfront.net"
                );
                script.setAttribute("async", "async");
                document.getElementsByTagName("head")[0].appendChild(script);
              }
            }

            /**
             * Inject the correct widget script depending on the widget container's 'data-ss_widget_type' attr
             * @param el  - widget container element
             */
            function injectWidget(el) {
              switch (el.getAttribute("data-ss_widget_type")) {
                case "gallery":
                  return loadScript(
                    "scorestream-gallery",
                    "https://cdn-scorestream-com.s3.amazonaws.com/cdn/js/bundles/galleryWidget.js?v=3"
                  );
                case "teamPage":
                  el.dataset.src = "https://scorestream.com/widgets/embedded";
                  el.dataset.widgetType = "teamPage";
                  return loadScript(
                    "scorestream-iframe",
                    "https://d2sgc57sw7l730.cloudfront.net/realtime/widgets/iframe.e35a8fa549878c585be4.bundle.js"
                  );
                case "compactTeamList":
                  return loadScript(
                    "scorestream-compactTeamList",
                    "https://d2sgc57sw7l730.cloudfront.net/realtime/widgets/compactTeamList.a9f4c53064dc7cdc0f5f.bundle.js"
                  );
                case "teamSearch":
                  return loadScript(
                    "scorestream-teamSearch",
                    "https://d2sgc57sw7l730.cloudfront.net/realtime/widgets/teamSearch.4d9a4ae263d64dea5832.bundle.js"
                  );
                case "teamSchedule":
                  el.dataset.src = "https://scorestream.com/widgets/embedded";
                  el.dataset.widgetType = "teamSchedule";
                  return loadScript(
                    "scorestream-iframe",
                    "https://d2sgc57sw7l730.cloudfront.net/realtime/widgets/iframe.e35a8fa549878c585be4.bundle.js"
                  );
                case "vertScoreboard":
                  el.dataset.src =
                    "https://scorestream.com/widgets/scoreboards/vert";
                  el.dataset.widgetType = "vertScoreboard";
                  return loadScript(
                    "scorestream-iframe",
                    "https://d2sgc57sw7l730.cloudfront.net/realtime/widgets/iframe.e35a8fa549878c585be4.bundle.js"
                  );
                case "horzScoreboard":
                  el.dataset.src =
                    "https://scorestream.com/widgets/scoreboards/horz";
                  el.dataset.widgetType = "horzScoreboard";
                  return loadScript(
                    "scorestream-iframe",
                    "https://d2sgc57sw7l730.cloudfront.net/realtime/widgets/iframe.e35a8fa549878c585be4.bundle.js"
                  );
                default:
                  console.log("Default Case");
              }
            }

            //All widget containers generated by the widget creator should have class 'scorestream-widget-container'
            //go through them all and inject the relevant widget scripts
            var widgetContainers = document.getElementsByClassName(
              "scorestream-widget-container"
            );
            widgetContainers = Array.prototype.slice.call(widgetContainers);
            widgetContainers.forEach(injectWidget);
          }, 1000)}
        </script>

        <footer className="Footer">
          <div style={{ fontSize: "16px", color: "#4d4d4d" }}>
            <LocationButton />
          </div>
          <div>
            <a
              href="../AboutUs"
              style={{
                fontSize: "16px",
                textDecoration: "none",
                color: "#4d4d4d",
              }}
            >
              About Us
            </a>
          </div>
          <div>
            <a
              href="mailto:Sales@shop-yourtown.com"
              style={{
                fontSize: "16px",
                textDecoration: "none",
                color: "#4d4d4d",
              }}
            >
              Contact Us
            </a>
          </div>
          <div>
            <a
              href="../Advertising"
              style={{
                fontSize: "16px",
                textDecoration: "none",
                color: "#4d4d4d",
              }}
            >
              Advertise
            </a>
          </div>
          <a
            href="https://www.facebook.com/pages/category/Discount-Store/Shopyourtown-2230097663912538/"
            className="fa fa-facebook"
            target="_blank"
            rel="noopener noreferrer"
          >
            {}
          </a>
          <br />
          <br />
          <div>
            <p
              style={{
                fontSize: "16px",
                textDecoration: "none",
                color: "#4d4d4d",
              }}
            >
              © 2019 SYT Solutions LLC
            </p>
          </div>
        </footer>
      </div>
    );
  }
}

export default NewLaunchAll;
