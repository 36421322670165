import React, { Component } from 'react';
import { BrowserRouter, Route, Switch} from 'react-router-dom';
import './styling/index.css';
import './styling/Ad-Modal.css';
import './styling/AppNew.css';
import NewLaunchAll from './widgets/NewLaunchAll';
import Home from './widgets/Home';
import NotFound from './widgets/NotFound';
import AboutUs from './widgets/AboutUs';
import AdWithUs from './widgets/AdWithUs';

class App extends Component {
  render() {
      return (
        <BrowserRouter>
          <Switch>
            <Route exact path={'/'} component={Home}/>
            <Route exact path={'/location/'} component={NewLaunchAll}/>
            <Route exact path={'/AboutUs'} component={AboutUs}/>
            <Route exact path={'/Advertising'} component={AdWithUs}/>
            <Route exact path={'/*'}component={NotFound}/>
          </Switch>
        </BrowserRouter>
    );
  }
}

export default App;
