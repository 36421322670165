import React, { Component } from "react";
import Input from "@material-ui/core/Input";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import SearchIcon from "@material-ui/icons/Search";
import Button from "@material-ui/core/Button";
import { Client } from "@googlemaps/google-maps-services-js";

const google_maps = new Client({});

class Home extends Component {
  constructor() {
    super();
    this.state = {
      count: 0,
      stores: [],
    };
  }

  componentDidMount() {
    var url = process.env.REACT_APP_STORE_DROPDOWN;
    fetch(url)
      .then((res) => res.json())
      .then((stores) => {
        this.getLocation(stores);
      });
  }

  getLocation(stores) {
    // Get all of the coordinates and push them to an array.
    const storeCoords = [];
    for (var x = 0; x < stores.length; x++) {
      const tempArray = [
        stores[x].StoreLatitude,
        stores[x].StoreLongitude,
        stores[x].StoreCity,
        stores[x].StoreState,
      ];
      storeCoords.push(tempArray);
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const alteredCoords = [];
          for (var y = 0; y < storeCoords.length; y++) {
            const tempArray2 = [
              Math.abs(storeCoords[y][0] - position.coords.latitude) +
                Math.abs(storeCoords[y][1] - position.coords.longitude),
              storeCoords[y][2],
              storeCoords[y][3],
              storeCoords[y][4],
            ];
            alteredCoords.push(tempArray2);
          }
          alteredCoords.sort();

          const city = alteredCoords[0][1];
          const state = alteredCoords[0][2];

          window.history.replaceState(
            [city, state],
            "City Info",
            "/location/?city=" + city + "&state=" + state
          );
          window.location.reload();
        },
        (error) => {
          console.error(error.message);
        }
      );
    } else {
      alert("Geolocation is not supported by this browser");
    }

    this.setState({ stores: storeCoords });
  }

  onFindLocation = () => {
    const _val = document.getElementById("zip").value;

    google_maps
      .geocode({
        params: {
          key: "AIzaSyCpi7WRMAu6YfV6RsMq-fMitBj55gACCGw",
          address: _val,
        },
      })
      .then((res) => {
        const coordinates = {
          latitude: res.data.results[0].geometry.location.lat,
          longitude: res.data.results[0].geometry.location.lng,
        };

        const _stores = this.state.stores
          .map((store) => ({
            distance:
              Math.abs(store[0] - coordinates.latitude) +
              Math.abs(store[1] - coordinates.longitude),
            city: store[2],
            state: store[3],
          }))
          .sort((a, b) =>
            a.distance < b.distance ? -1 : a.distance > b.distance ? 1 : 0
          );

        window.history.replaceState(
          [_stores[0].city, _stores[0].state],
          "City Info",
          `/location/?city=${_stores[0].city}&state=${_stores[0].state}`
        );
        window.location.reload();
      })
      .catch((e) => {
        console.error(`google_services.geocode: ${e}`);
      });
  };

  render() {
    return (
      <div
        className="App flex-align-center flex-just-center flex-column"
        style={{ height: "100%" }}
      >
        <meta
          name="description="
          content="Shop Your Town. Shop local businesses for discounts, coupons, deals and incentives. Access local government and commerce links. Catch up on local weather, news and high school sports."
        ></meta>
        <div>
          <img src="/image/SYTlogoFC.png" width="100%"></img>
        </div>
        <div
          className="flex-column"
          style={{ height: "100%", marginTop: "8%" }}
        >
          <div className="flex-align-center flex-column landing">
            <h1>Shop Your Town For Local Discounts</h1>
            <div className="vert-margin-med">
              <FormControl>
                <Input
                  id="zip"
                  type="text"
                  placeholder="Enter your ZIP..."
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </FormControl>
            </div>

            <div>
              <Button
                className="button-primary"
                variant="contained"
                color="primary"
                onClick={this.onFindLocation}
              >
                Go
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
