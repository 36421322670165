import React, { Component } from "react";
import LocationButton from "./LocationButton";
class AboutUs extends Component {
  constructor() {
    super();
    this.state = {
      stores: [],
      count: 0,
      suggestions: [],
      cities: [],
    };
  }

  // Fetch the information from the database
  componentDidMount() {
    var storeUrl = process.env.REACT_APP_STORE_DROPDOWN;

    fetch(storeUrl)
      .then((res) => res.json())
      .then((stores) => this.setState({ stores }));
    //axios.get(storeUrl).then(res => {const stores = res.data; this.setState({stores})});
  }

  generateDropdown() {
    //if(this.state.count === 16){
    // Get all of the coordinates and push them to an array.
    const storeCoords = [];

    for (var x = 0; x < this.state.stores.length; x++) {
      const tempArray = [
        this.state.stores[x].StoreCity +
          "," +
          this.state.stores[x].StoreState +
          "," +
          this.state.stores[x].StoreID,
      ];
      storeCoords.push(tempArray);
    }

    storeCoords.sort();
    storeCoords.reverse();

    this.setState({ cities: storeCoords });
  }

  onTextChanged = (e) => {
    this.generateDropdown();
    const value = e.target.value;
    let suggestions = [];

    if (value.length > 0) {
      const regex = new RegExp(`^${value}`, "i");
      suggestions = this.state.cities.sort().filter((v) => regex.test(v));
      this.setState(() => ({ suggestions }));
      document.getElementsByClassName("closeBtn1")[0].style.display = "block";
    }
    if (document.getElementsByClassName("Dropdown-Content")[0] !== undefined) {
      document.getElementsByClassName("Dropdown-Content")[0].style.display =
        "block";
    }
  };

  renderSuggestions() {
    const { suggestions } = this.state;
    var cityArray = suggestions.map((cities) => cities);
    var splitArray = [];
    var formattedRows = [];

    if (suggestions.length === 0) {
      return null;
    }

    for (var z = 0; z < cityArray.length; z++) {
      splitArray.push(cityArray[z][0].split(","));
    }

    for (var y = 0; y < splitArray.length; y++) {
      formattedRows.push(
        <tr key={y}>
          <a
            href={
              "../location/?city=" +
              splitArray[y][0] +
              "&state=" +
              splitArray[y][1]
            }
          >
            {splitArray[y][0] + ", " + splitArray[y][1]}
          </a>
        </tr>
      );
    }

    return <div className="Dropdown-Content">{formattedRows}</div>;
  }

  clear() {
    var input = document.getElementsByClassName("CityInput1")[0];
    var div = document.getElementsByClassName("Dropdown-Content")[0];

    if (div !== undefined) {
      input.value = "";
      div.style.display = "none";
      document.getElementsByClassName("closeBtn1")[0].style.display = "none";
    }
  }

  renderTextBox() {
    if (document.getElementsByClassName("A2")[0] !== "") {
      document.getElementsByClassName("A2")[0].innerHTML = "";
      document.getElementsByClassName("A2")[0].onClick = null;
      document.getElementsByClassName("A2")[0].style.width = "0%";
      document.getElementsByClassName("A2Input")[0].style.width = "14%";
      document.getElementsByClassName("A2Input")[0].style.display = "block";
    }
  }

  render() {
    document.title = "Shop Your Town | About Us";

    return (
      <div className="MasterWrapper">
        <div className="MasterHeader">
          <div className="Spacer2"></div>
          <div className="A1">
            <LocationButton />
          </div>
          <div className="Spacer4"></div>
          <div
            className="A2"
            style={{ cursor: "pointer" }}
            onClick={this.renderTextBox}
          >
            Find Cities
          </div>
          <div className="A2Input">
            <input
              className="CityInput"
              placeholder="City Search..."
              type="text"
              onChange={this.onTextChanged}
              autocomplete="off"
            ></input>
            <span className="closeBtn1" onClick={this.clear}>
              &times;
            </span>
            {this.renderSuggestions()}
          </div>
          <div className="Spacer6"></div>
          <div className="LogoContainer">
            <a href="../">
              <img
                className="SYTLogoMain"
                src="/image/SYTlogoFC.png"
                alt="SYT-Logo"
              ></img>
            </a>
          </div>
          <div className="Spacer4"></div>
          <div className="A3">
            <a
              href="../AboutUs"
              style={{ textDecoration: "none", color: "black" }}
            >
              About Us
            </a>
          </div>
          <div className="Spacer4"></div>
          <div className="A4">
            <a
              href="mailto:Sales@shop-yourtown.com?subject=Website Inquiry"
              style={{ textDecoration: "none", color: "black" }}
            >
              Contact Us
            </a>
          </div>
          <div className="Spacer4"></div>
          <div className="A5">
            <a
              href="../Advertising"
              style={{ textDecoration: "none", color: "black" }}
            >
              Advertise
            </a>
          </div>
        </div>

        <script>
          {
            (window.onscroll = function () {
              var header = document.getElementsByClassName("MasterHeader")[0];
              //var sticky = header.offsetTop;

              if (
                document.body.scrollTop > 1 ||
                document.documentElement.scrollTop > 1
              ) {
                header.classList.add("StickySmall");
              } else {
                header.classList.remove("StickySmall");
              }
            })
          }
        </script>

        {window.scrollTo(0, 0)}

        <div className="AboutUsContainer">
          <p>
            <b
              style={{ textAlign: "left", color: "#0037FF", fontSize: "30px" }}
            >
              What is, www.Shop-YourTown.com:{" "}
            </b>
          </p>
          <p style={{ textAlign: "left", fontSize: "18px", lineHeight: "1.5" }}>
            Shop-YourTown.com is not just a catchy website name, it’s a call to
            action. <b style={{ color: "#0037FF" }}>Our Mission</b> is as much
            civic, as it is for profit. We provide an online destination for
            YourTown that promotes and encourages shopping at the businesses in
            YourTown through offers and discounts. It’s not that complicated but
            before www.Shop-Yourtown.com there hasn’t been an online destination
            for YourTown that works as hard as we do to provide a platform to
            local government, economic development and city planners a platform
            that brings citizens and businesses together for the good of your
            community.
          </p>
          <br />
          <p>
            <b
              style={{ textAlign: "left", color: "#0037FF", fontSize: "30px" }}
            >
              What does Shop-YourTown mean to YourTown?
            </b>
          </p>
          <p style={{ textAlign: "left", fontSize: "18px", lineHeight: "1.5" }}>
            Supporting local businesses not only makes YourTown better
            economically but socially. The simple benefit is jobs, jobs lower
            unemployment lower unemployment provides stability, stability
            reduces the social and economic strains on your local government.
            All of these things attract larger corporations with higher paying
            jobs that increase the tax base which can lower property taxes or
            make investments into YourTown schools, roads and infrastructure
            that make YourTown a safer and better place to live, work and raise
            a family.
          </p>
        </div>

        <footer className="Footer">
          <div style={{ fontSize: "16px", color: "#4d4d4d" }}>
            <LocationButton />
          </div>
          <div>
            <a
              href="../AboutUs"
              style={{
                fontSize: "16px",
                textDecoration: "none",
                color: "#4d4d4d",
              }}
            >
              About Us
            </a>
          </div>
          <div>
            <a
              href="mailto:Sales@shop-yourtown.com"
              style={{
                fontSize: "16px",
                textDecoration: "none",
                color: "#4d4d4d",
              }}
            >
              Contact Us
            </a>
          </div>
          <div>
            <a
              href="../Advertising"
              style={{
                fontSize: "16px",
                textDecoration: "none",
                color: "#4d4d4d",
              }}
            >
              Advertise
            </a>
          </div>
          <a
            href="https://www.facebook.com/pages/category/Discount-Store/Shopyourtown-2230097663912538/"
            className="fa fa-facebook"
            target="_blank"
            rel="noopener noreferrer"
          >
            {}
          </a>
          <br />
          <br />
          <div>
            <p
              style={{
                fontSize: "16px",
                textDecoration: "none",
                color: "#4d4d4d",
              }}
            >
              © 2019 SYT Solutions LLC
            </p>
          </div>
        </footer>
      </div>
    );
  }
}

export default AboutUs;
