import React, { Component } from 'react';
//import axios from 'axios';
import $ from 'jquery';

class LocationButton extends Component{
    constructor(){
        super();
        this.state = {
            stores: []
        }
    }

    componentDidMount(){
        var url = process.env.REACT_APP_STORE_DROPDOWN;
        fetch(url).then(res => res.json()).then(stores => this.setState({stores}));
        //axios.get(url).then(res => {const stores = res.data; this.setState({stores})});
    }

    getLocation(){
        // Get all of the coordinates and push them to an array.
        const storeCoords = [];
        for(var x = 0; x < this.state.stores.length; x++){
            const tempArray = [this.state.stores[x].StoreLatitude, this.state.stores[x].StoreLongitude, this.state.stores[x].StoreCity, this.state.stores[x].StoreState]
            storeCoords.push(tempArray);
        }

        if(navigator.geolocation){
            navigator.geolocation.getCurrentPosition(showPosition, showError);
        } else {
            alert("Geolocation is not supported by this browser");
        }

        // Get the user's position and compare it to what's in the database.
        function showPosition(position){
            const alteredCoords = [];
            for(var y = 0; y < storeCoords.length; y++){
                const tempArray2 = [Math.abs(storeCoords[y][0] - position.coords.latitude) + Math.abs(storeCoords[y][1] - position.coords.longitude), storeCoords[y][2], storeCoords[y][3], storeCoords[y][4]]
                alteredCoords.push(tempArray2);
            }
            alteredCoords.sort();

            const city = alteredCoords[0][1];
            const state = alteredCoords[0][2];

            window.history.replaceState([city, state], 'City Info', '/location/?city='+city+'&state='+state);
            window.location.reload();
        }  
        function showError(){
            $.ajax({
                type: 'GET',
                dataType: 'json',
                //url: 'http://www.geoplugin.net/json.gp',
                url: 'https://www.geoip-db.com/json/',

                success: function(responseData){
                    const alteredCoords2 = [];
                    for(var y = 0; y < storeCoords.length; y++){
                        const tempArray2 = [Math.abs(storeCoords[y][0] - responseData.latitude) + Math.abs(storeCoords[y][1] - responseData.longitude), storeCoords[y][2], storeCoords[y][3], storeCoords[y][4]]
                        alteredCoords2.push(tempArray2);
                    }
                    alteredCoords2.sort();

                    const city = alteredCoords2[0][1];
                    const state = alteredCoords2[0][2];
        
                    window.history.replaceState([city, state], 'City Info', '/location/?city='+city+'&state='+state);
                    window.location.reload();
                }
            })
        }

        // Change the url and redirect the user.
    }

    render(){
        return(
            <div>
                {/* eslint-disable-next-line */}
                <a className="VisitMyTown" onClick={() => this.getLocation()}>Visit Your Town</a>
            </div>
        )
    }

}

export default LocationButton;