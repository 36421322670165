import React, { Component } from "react";

export default class Card extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className={this.props.dims.size} id={`AD${this.props.id}`}>
        <div style={{ height: "fit-content", margin: "auto", width: "78%" }}>
          <div
            style={{
              width: this.props.dims.type ? "50%" : "100%",
              height: "100%",
              margin: "auto",
            }}
          >
            <a
              id={`Overlay${this.props.id + 1}`}
              style={{ cursor: "pointer" }}
              title="Click here to save this offer!"
              href={this.props.img_src}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                id={`AdImage${this.props.id + 1}`}
                alt={this.props.name}
                height={this.props.dims.height}
                width={this.props.dims.width ? this.props.dims.width : "100%"}
                src={this.props.img_src}
              ></img>
            </a>
          </div>
        </div>
        <div className="AdDescription">
          <p style={{ fontSize: "10px", opacity: "0" }}>Placeholder</p>
          <p style={{ fontSize: "16px" }}>
            <b>{this.props.name}</b>
          </p>
          <p style={{ fontSize: "14px" }}>{this.props.address}</p>
          <p style={{ fontSize: "14px" }}>{this.props.phone}</p>
          <button
            className="MoreInfoButton"
            id={`Button${this.props.id + 1}`}
            onClick={() => this.props.onClick(this.props.id + 1)}
          >
            <span>More Info </span>
          </button>
        </div>
      </div>
    );
  }
}
